<template>
  <div class="v-wrapper tool--none" :class="{'is-animate': isAnimating}">
    <div class="v-nav" v-if="isOnSearch" key="tree">
      <tree-nav @return="isOnSearch = !isOnSearch" :show-return="true" @click-device="clickDevice" :cache="false"
        @click-batch="p => clickDevice(0, p[0])"></tree-nav>
    </div>
    <div class="v-nav v-places" v-else key="panel" :class="$i18n.locale">
      <div class="v-area">
        <p class="v-area__title" v-if="false">{{$t('placeManage.name')}}
          <i class="el-icon-search" style="float: right;" @click="isOnSearch = !isOnSearch"></i>
        </p>
        <div class="ipy-0" style="padding-bottom:0">
          <div class="v-item">
            <span class="v_title">{{$t('placeManage.deviceName')}}：</span>
            <span class="v_text searchIcon" :title="$t('placeManage.placeHolderSearch')"
              @click="isOnSearch = !isOnSearch" v-if="itemId">{{group.vehicle.VehicleNum}}<i
                class="el-icon-search"></i></span>
            <span class="v_text searchIcon" :title="$t('placeManage.placeHolderSearch')"
              @click="isOnSearch = !isOnSearch" v-else>{{$t('placeManage.placeHolderFind')}} <i
                class="el-icon-search"></i></span>
          </div>
          <div class="v-item">
            <span class="v_title">{{$t('placeManage.deviceList')}}：</span>
            <div class="v_text v-device">
              <el-radio v-model="objectId" :label="item.ObjectID" v-for="item in group.devices" :key="item.ObjectID">
                {{item.SIM2}}</el-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="v-place">
        <h2>{{$t('placeManage.parma')}}</h2>
        <div class="dateRadios">
          <span class="i-link" @click="setRangeToday">{{$t('onlineTrack.today')}}</span>
          <span class="i-link" @click="setRangeYesterday">{{$t('onlineTrack.yestoday')}}</span>
          <span class="i-link" @click="setRangeBeforeYesterday">{{$t('onlineTrack.beforeYestoday')}}</span>
          <span class="i-link" @click="setRangeThreeDays">{{$t('onlineTrack.threeDay')}}</span>
        </div>
        <div class="v-lines">
          <span class="v_title">{{$t('placeManage.begin')}}：</span>
          <div class="v_text">
            <el-date-picker type="datetime" :size="size" :clearable="false" format="yyyy-MM-dd HH:mm"
              v-model="setting.startTime" :placeholder="$t('placeManage.placeHolderTime')">
            </el-date-picker>
          </div>
        </div>
        <div class="v-lines">
          <span class="v_title">{{$t('placeManage.end')}}：</span>
          <div class="v_text">
            <el-date-picker type="datetime" :size="size" :clearable="false" format="yyyy-MM-dd HH:mm"
              v-model="setting.endTime" :placeholder="$t('placeManage.placeHolderTime')">
            </el-date-picker>
          </div>
        </div>
        <div class="v-lines">
          <span class="v_title">{{$t('placeManage.stay')}}：</span>
          <div class="v_text tingliu">
            <el-input :size="size" :maxlength="4" v-model.trim="setting.stopMinutes" :disabled="setting.searchMode===2"
              oninput="value=value.replace(/[^\d]/g, '')">
              <span slot="prepend">{{$t('placeManage.lt')}}</span>
              <span slot="suffix">{{$t('placeManage.min')}}</span>
            </el-input>
          </div>
        </div>
        <div class="v-lines">
          <span class="v_title">{{$t('placeManage.modes')}}：</span>
          <div class="v_text v-device">
            <el-radio v-model="setting.searchMode" :label="1">{{$t('placeManage.mode1')}}</el-radio> <br>
            <el-radio v-model="setting.searchMode" :label="2">{{$t('placeManage.mode2')}}</el-radio>
          </div>
        </div>
        <div class="v-btns">
          <el-button type="primary" class="border" plain :loading="btnLoading" @click="search" :disabled="!itemId">
            {{$t('placeManage.btn')}}</el-button>
        </div>
      </div>
    </div>
    <div class="subBlock">
      <div class="v-pano" :class="{'is-show': isShowRealScene}">
        <div v-show="isShowRealScene" style="z-index: 9" class="v-wrap">
          <iframe id="mainIframe" ref="mainIframe" class="box" src="https://www.365qczx.com/Jiejing/StreetBD2.html"
            frameborder="0"></iframe>
        </div>
        <span class="closeBtn" @click="isShowRealScene=false" v-if="isShowRealScene"><i
            class="el-icon-close"></i></span>
      </div>

      <div class="v-body" ref="container"></div>
      <div class="v-tool">
        <!-- <p class="imr-3 i-link" @click="fitView">{{$t('placeManage.showAll')}}</p>
        <p class="imr-3 i-link" @click="showHomeAddress">{{$t('placeManage.showHome')}}</p>
        <p class="imr-3 i-link" @click="showCompanyAddress">{{$t('placeManage.showCompany')}}</p> -->
        <p>{{$t('placeManage.layout1')}}：</p>
        <el-checkbox class="imr-3" v-model="setting.showSatellite">{{$t('placeManage.show')}}</el-checkbox>
        <p>{{$t('placeManage.layout2')}}：</p>
        <el-checkbox class="imr-3" v-model="setting.showRoadMap">{{$t('placeManage.show')}}</el-checkbox>
        <!-- <p>详情：</p>
          <el-checkbox class="imr-3" v-model="isShowRightNav">显示</el-checkbox> -->
        <p v-if="isShowRealScene">{{$t('placeManage.showCompany')}}：</p>
        <el-checkbox class="imr-3" v-if="isShowRealScene" v-model="isShowRealScene">{{$t('placeManage.show')}}
        </el-checkbox>
        <p>
          <change-map style="cursor:pointer"></change-map>
        </p>
      </div>
      <arrow-button @click="isShowRightNav = !isShowRightNav" class="toggleTable" :class="{'expand':isShowRightNav}"
        :position="isShowRightNav?'right':'left'"></arrow-button>
      <transition name="slide-left">
        <div class="v-nav--right" v-show="isShowRightNav">
          <div v-if="setting.searchMode===1">
            <p class="sub-title ipx-0">
              <i class="el-icon-arrow-right" @click="isShowRightNav = !isShowRightNav"></i>
              {{$t('placeManage.pointerDetail')}} <el-button class="action" @click="exportPark"
                :loading="exportExcelLoading" type="text"><i class="el-icon-download"></i>{{$t('placeManage.export')}}
              </el-button>
            </p>
            <div class="park" style="height:calc(100vh - 88px);overflow-y:auto;padding-top:0;">
              <div class="block" v-for="(row,index) in park" :key="index" @click="rowClick(row)">
                <h1>{{miment(row.day).format()}}</h1>
                <div class="box">
                  <div class="caption">
                    <div class="times"><span>
                        <i-icon name="iconchangtingdian_shijian"></i-icon>
                      </span>{{`${miment(row.startTime).format('hh:mm')} ~ ${miment(row.endTime).format('hh:mm')}`}}
                    </div>
                    <div class="mile"> {{row.duration}}</div>
                  </div>
                  <div class="row">
                    <span>
                      <i-icon name="iconzhaochangtingdian1"></i-icon>
                    </span>
                    <p>
                      <span-address :lon="row.slon" :lat="row.slat" show-default></span-address>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="sub-title ipx-0">
              <i class="el-icon-arrow-right" @click="isShowRightNav = !isShowRightNav"></i>
              {{$t('placeManage.roadDetail')}}<el-button class="action" @click="exportTrip"
                :loading="exportExcelLoading" type="text"><i class="el-icon-download"></i>{{$t('placeManage.export')}}
              </el-button>
            </p>
            <div class="park" style="height:calc(100vh - 88px);overflow-y:auto;padding-top:0;">

              <div class="block" v-for="(row,index) in trip" :key="index" @click="rowClick(row)">
                <h1>{{miment(row.day).format()}}</h1>
                <div class="box">
                  <div class="caption">
                    <div class="times"><span>
                        <i-icon name="iconchangtingdian_shijian"></i-icon>
                      </span><em>{{`${miment(row.startTime).format('hh:mm')} ~ ${miment(row.endTime).format('hh:mm')}`}}</em>
                    </div>
                    <div class="times"><span>
                        <i-icon name="iconlicheng2"></i-icon>
                      </span> <em>{{row.mile}}</em> km</div>
                    <div class="mile"> <em>{{row.desc}}</em></div>
                  </div>
                  <div class="row">
                    <span>
                      <i-icon name="iconqidian"></i-icon>
                    </span>
                    <p>
                      <span-address :lon="row.beginSLon" :lat="row.beginSLat" show-default></span-address>
                    </p>
                  </div>
                  <div class="row">
                    <span>
                      <i-icon name="iconzhongdian"></i-icon>
                    </span>
                    <p>
                      <span-address :lon="row.endSLon" :lat="row.endSLat" show-default></span-address>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <!-- </main-wrapper> -->
</template>

<script>
  import {
    VclCode
  } from 'vue-content-loading'
  import {
    GetTrip,
    GetPark
  } from '@/api/risk-place'
  import {
    QueryVehicleComplexByObjectID
  } from '@/api/vehicle'
  import {
    ExportTrip,
    ExportPark
  } from '@/api/report'
  import {
    QueryTrackDataFilter
  } from '@/api/common'
  import {
    remoteLoad,
    saveBlob,
    bd_encrypt,
    translateHour
  } from '@/common/utils'
  import TreeNav from '@/views/common/tree-nav'
  import miment from 'miment'
  import {
    mapGetters
  } from 'vuex'
  import mixinMapCachePosition from '@/views/mixins/mixin-map-cache-position'
  import ArrowButton from '@/components/arrow-button'
  import ChangeMap from '@/views/common/change-map'
  import {GetWrapper, GetTextView} from '@/views/online/google-map.js'
  import { getGoogleKey } from '@/common/cache'
  export default {
    name: 'riskPlace',
    props: {
      itemId: {
        type: Number,
        default: null
      }
    },
    components: {
      ArrowButton,
      VclCode,
      ChangeMap,
      TreeNav
    },
    data() {
      return {
        size: 'small',
        btnLoading: false,
        objectId: null,
        setting: {
          searchMode: 1,
          startTime: miment('2018-08-01 00:00:00'),
          endTime: miment('2018-08-14 00:00:00'),
          stopMinutes: 180,
          showSatellite: false,
          showRoadMap: false
        },
        group: {
          vehicle: {},
          people: {},
          devices: [],
          holdInfo: {}
        },
        table: {
          pageSize: 10,
          pageIndex: 1
        },
        park: [],
        trip: [],
        isShowRightNav: false,
        isAnimating: false,
        isOnSearch: false,
        isShowRealScene: false,
        exportExcelLoading: false,
        defindMap: '',
        cluster:null
      }
    },
    watch: {
      itemId(val) {
        this.objectId = val
      },
      currentMap(a, b) {
        this.defindMap = a
        if (!a&& a === b) {
          return false
        }
        
        window.location.reload()
        this.initMap(a)
      },
      objectId(val) {
        this.clearAllMakers()
        this.isShowRightNav = false
        if(!val){
          this.group.devices = []
        }
        val&&this.$router.replace(`/risk/place?id=${val}`)
      },
      $route() {
        const index = this.group.devices.findIndex(k => k.ObjectID == this.objectId)
        if (index === -1) {
          this.fetchData()
        }
      },
      'setting.searchMode': {
        handler() {
          this.clearAllMakers()
          this.isShowRightNav = false
        },
        deep: true
      },
      'setting.showSatellite': {
        handler(val) {
          if(this.google){
            val ? this.map.setMapTypeId(this.google.maps.MapTypeId.SATELLITE) : this.map.setMapTypeId(this.google.maps.MapTypeId.ROADMAP) 
          }else if(this.BMap){
            var traffic = new window.BMap.TrafficLayer()
            // val ?this.map.addTileLayer(traffic) : this.map.removeTileLayer(traffic)
          }else{
            val ? this.mapLayer.satelliteLayer.show() : this.mapLayer.satelliteLayer.hide()
          }
        },
        deep: true
      },
      'setting.showRoadMap': {
        handler(val) {
          if(window.google){
             val ? this.map.setMapTypeId(this.google.maps.MapTypeId.TERRAIN) : this.map.setMapTypeId(this.google.maps.MapTypeId.ROADMAP) 
          }else{
            val ? this.mapLayer.trafficLayer.show() : this.mapLayer.trafficLayer.hide()
          }
        },
        deep: true
      }
    },
    computed: {
      tripPageData() {
        let start = (this.table.pageIndex - 1) * this.table.pageSize
        let totalSlice = this.table.pageSize * this.table.pageIndex
        const partList = this.trip.slice(start, totalSlice)
        return partList
      },
      parkPageData() {
        let start = (this.table.pageIndex - 1) * this.table.pageSize
        let totalSlice = this.table.pageSize * this.table.pageIndex
        const partList = this.park.slice(start, totalSlice)
        return partList
      },
      ...mapGetters([
        'currentMap'
      ])
    },
    methods: {
        initMap(defindMap, center = [116.397428, 39.90923], zoom = 11){
          return new Promise(async (resolve) => {
            const position = this.loadPosition() // mixin
            center = position.center
            zoom = position.zoom
           
            if (defindMap === 'Amap') {
              // if (!window.AMap) {
              //   await remoteLoad(this.$AMAP_URL)
              // }
              // if (!window.AMapUI) {
              //   await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              // }
              await remoteLoad(this.$AMAP_URL)
              await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
              let map = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                zooms: [3, 20],
                expandZoomRange: true,
                center
              })
              this.map = map
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              await this.initLayer()
              // await this.initRealScene()
            } else if(defindMap === 'Bmap'){
              // if (!window.BMap) {
              //   window.BMap_loadScriptTime = (new Date).getTime()
              //   window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
              //   await remoteLoad(this.$BMAP_URL)
              // }
              window.BMap = null
              window.BMap_loadScriptTime = (new Date).getTime()
              window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
              await remoteLoad(this.$BMAP_URL)
              

              // if (!window.BMapLib) {
              //   await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
              //   await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
              //   await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
              //   await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
              // }
              await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
              await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
              await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
              await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')

              let map2 = new window.BMap.Map(this.$refs.container, { enableMapClick: false })
              map2.centerAndZoom(new window.BMap.Point(center[0], center[1]), zoom)
              map2.enableScrollWheelZoom(true)
              map2.enableContinuousZoom()
              map2.clearHotspots()

              let mapType = new window.BMap.MapTypeControl(
                {
                  mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
                  anchor: window.BMAP_ANCHOR_TOP_RIGHT
                }
              )
              map2.addControl(mapType)

              this.map = map2
              this.BMap = window.BMap

              // 清空地图
              this.BMap.Map.prototype.clearMap = this.BMap.Map.prototype.clearOverlays
              this.BMap.Map.prototype.on = this.BMap.Map.prototype.addEventListener
              this.BMap.Map.prototype.off = this.BMap.Map.prototype.removeEventListener
              // 关闭信息窗体
              this.BMap.Map.prototype.clearInfoWindow = this.BMap.Map.prototype.closeInfoWindow
              // 打开信息窗体
              this.BMap.InfoWindow.prototype.open = function (m, p) {
                p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
                m.openInfoWindow(this, p)
              }
              // 关闭信息窗体
              this.BMap.InfoWindow.prototype.close = () => this.map.closeInfoWindow()
              // 信息窗体设置位置
              this.BMap.InfoWindow.prototype.setPosition = function (p) {
                p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
                this.isOpen() && map.openInfoWindow(this, p)
              }
              this.BMap.InfoWindow.prototype.getIsOpen = this.BMap.InfoWindow.prototype.isOpen
              // Overlay属性
              this.BMap.Overlay.prototype.setMap = function(m) { m ? m.addOverlay(this) : (this.getMap() && this.getMap().removeOverlay(this))}
              this.BMap.Overlay.prototype.on = this.BMap.Overlay.prototype.addEventListener
              this.BMap.Overlay.prototype.off = this.BMap.Overlay.prototype.removeEventListener
              this.BMap.Overlay.prototype.getExtData = function () { return this.extData }
              this.BMap.Overlay.prototype.setExtData = function (v) { this.extData = v }
              // Point属性
              this.BMap.Point.prototype.getLng = function () { return this.lng }
              this.BMap.Point.prototype.getLat = function () { return this.lat }


            }else if(defindMap === 'Google'){
              if (!window.google){
                let gmap_url = getGoogleKey() ?`https://maps.googleapis.com/maps/api/js?key=${getGoogleKey()}&libraries=drawing&language=en-US` :this.$GOOGLE_URL_EN
                await remoteLoad(gmap_url)
                // await remoteLoad(this.$GOOGLE_URL_EN)
              }
              await remoteLoad('https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js')
              let maxLat = Math.atan(Math.sinh(Math.PI)) * 180 / Math.PI;
              let map = new window.google.maps.Map(this.$refs.container, {
                center: { lng: center[0],lat: center[1] },
                minZoom:3,
                maxZoom:22,
                zoom: zoom,
                fullscreenControl:false,
                mapTypeControl:false,
                mapTypeControlOptions: {
                  position: window.google.maps.ControlPosition.RIGHT_TOP
                },
                restriction: {
                  latLngBounds: {north: maxLat, south: -maxLat, west: -180, east: 180},
                  strictBounds: true
                },
                addressControlOptions:{
                  position: window.google.maps.ControlPosition.LEFT_CENTER
                },
              })
              this.map = map
              this.google = window.google
              let panorama = this.map.getStreetView();
              panorama.setOptions({
                addressControlOptions:{
                  position: window.google.maps.ControlPosition.LEFT_CENTER
                },
                zoomControlOptions:{
                  position: window.google.maps.ControlPosition.LEFT_CENTER
                },
              })
              this.Wrapper = GetWrapper(window.google)
              this.ifNotExistsWindow()
            }
            else {
              if (!window.AMap) {
                await remoteLoad(this.$AMAP_URL)
              }
              if (!window.AMapUI) {
                await remoteLoad('//webapi.amap.com/ui/1.0/main.js?v=1.0.11')
              }
              if (!this.SimpleMaker) {
                await this.initSimpleMarker()
              }
              if (defindMap === 'QQmap') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl(x, y, z) {
                    let ty = Math.pow(2, z) - 1 - y
                    return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(ty/16.0)}/${x}_${ty}.png?version=20190101`
                  },
                  zIndex: 2
                });
              } else if (defindMap === 'Google') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
                  zIndex: 2
                });
              } else if (defindMap === 'Openstreet') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl: '//a.tile.openstreetmap.org/[z]/[x]/[y].png',
                  zIndex: 2
                });
              } else if (defindMap === 'Bing') {
                var layer = new window.AMap.TileLayer({
                  getTileUrl: (x, y, z) => {
                    let code = new Array(z)
                    let nex = Math.pow(2, z - 1)
                    for (let i = 0; i < code.length; i++) {
                      let ox = Math.floor(x / nex) % 2
                      let oy = (Math.floor(y / nex) % 2) * 2
                      code[i] = ox + oy
                      nex = nex / 2
                    }
                    let imgNumber = code.join('')
                    return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
                  },
                  zIndex: 2
                });
              } else {
                console.log('错误')
              }
              let map = new window.AMap.Map(this.$refs.container, {
                resizeEnable: true,
                zoom,
                center,
                isHotspot: false,
                features: [],
                preloadMode: false,
                zooms: [3, 21],
                layers: [layer],
                expandZoomRange: false
              })
              this.map = map
              this.AMap = window.AMap
              this.AMapUI = window.AMapUI
              this.ifNotExistsWindow()
              await this.initLayer()
              // await this.initRealScene()
            }
           resolve();
        });
      },
      initLayer() {
        const promise = new Promise((resolve, reject) => {
          this.map.plugin(["AMap.MarkerClusterer"], () => {
            this.mapLayer = {}
            this.mapLayer.trafficLayer = new window.AMap.TileLayer.Traffic()
            this.mapLayer.satelliteLayer = new window.AMap.TileLayer.Satellite()
            this.mapLayer.trafficLayer.setMap(this.map)
            this.mapLayer.satelliteLayer.setMap(this.map)
            this.mapLayer.trafficLayer.hide()
            this.mapLayer.satelliteLayer.hide()
            resolve()
          }).catch(err => {
            reject(err)
          })
        })
        return promise
      },
      showRealScene(lng, lat, heading, objectId) {
        // if (this.infoWindow.getId() != objectId) {
        //   return
        // }
        let data = {
          Lng: bd_encrypt(lng, lat).bd_lng,
          Lat: bd_encrypt(lng, lat).bd_lat,
          Direct: 90
        }
        this.isShowRealScene = true
        let jiejing = this.$refs['mainIframe']
        jiejing.contentWindow.postMessage(data, 'https://www.365qczx.com/Jiejing/StreetBD2.html');
      },
      fetchData() {
        const promise = new Promise((resolve) => {
          if (!this.itemId) {
            this.group.vehicle = {}
            resolve()
            return
          }
          QueryVehicleComplexByObjectID(this.itemId).then(ret => {
            this.group.vehicle = ret.data.devices[0]
            this.group.devices = ret.data.devices
            this.group.holdInfo = ret.data.holdInfo
            this.group.people = ret.data.people
            resolve(this.group)
          })
        })
        return promise
      },
      fetchTrip() {
        const promise = new Promise((resolve) => {
          if (!this.itemId) {
            resolve()
            return
          }
          GetTrip(this.itemId, this.setting.startTime, this.setting.endTime).then(ret => {
            let trip = ret.data.slice()
            trip.length&&trip.forEach(k=>{
              k.desc = translateHour(k.desc,this.$i18n.locale)
            })
            this.trip = trip
            resolve(this.trip)
          })
        })
        return promise
      },
      fetchPark() {
        const promise = new Promise((resolve) => {
          if (!this.itemId) {
            resolve()
            return
          }
          GetPark(this.itemId, this.setting.stopMinutes, this.setting.startTime, this.setting.endTime).then(ret => {
           let park = ret.data.slice()
            park.forEach(k=>{
              k.duration = translateHour(k.duration,this.$i18n.locale)
            })
            this.park = park
            resolve(this.park)
          })
        })
        return promise
      },
      fetchTrackData(startTime, endTime) {
        const promise = new Promise((resolve) => {
          QueryTrackDataFilter(this.objectId, startTime, endTime, false, false).then(ret => {
            resolve(ret.data.list)
          })
        })
        return promise
      },
      initSimpleMarker() {
        const promise = new Promise((resolve) => {
          window.AMapUI.loadUI(['overlay/SimpleMarker'], SimpleMaker => {
            this.SimpleMaker = SimpleMaker
            resolve()
          })
        })
        return promise
      },
      ifNotExistsWindow() {
        this.infoWindow = null
        if (!this.infoWindow) {
          if (this.currentMap === 'Bmap'){
            let infoWindow = new this.BMap.InfoWindow('', {
              width: 0,
              height: 0,
              offset: new this.BMap.Size(-18, -52),
              enableCloseOnClick: false,
              enableAutoPan: false
            })

            // 地图
            infoWindow.map = this.map

            // 根据getId获取当前监控车辆
            infoWindow.setId = id => infoWindow._id = id
            infoWindow.getId = () => infoWindow._id
            this.infoWindow = infoWindow
          }else if(window.google){
             if (!this.infoWindow) {
              let infoWindow = new this.Wrapper(
                  this.map,
                  new this.google.maps.LatLng(0, 0),
                  { el: document.createElement('div'), offsetY: -42 },
                  null)

                this.infoWindow = infoWindow
              }
          }else{
            let infoWindow = new window.AMap.InfoWindow({
              isCustom: true,
              content: '',
              offset: new window.AMap.Pixel(0, -42)
            })
            infoWindow.on('close', () => {
              this.isTrackInfoOpen = false
            })
            this.infoWindow = infoWindow
          }
        }
      },
      async search() {
        let data
        this.btnLoading = true
        if (this.setting.searchMode === 1) {
          // 查询停车
          data = await this.fetchPark()
        } else if (this.setting.searchMode === 2) {
          // 查询路线
          data = await this.fetchTrip()
        }
        this.btnLoading = false
        if (data.length === 0) {
          this.$message('没有符合查询条件的信息')
          return
        }
        this.isShowRightNav = true
        this.pageIndex = 1
        if (this.setting.searchMode === 1) {
          this.clearAllMakers()
          if(this.currentMap=== 'Bmap'){
            this.addMarkersBD()
          }else if(this.currentMap==='Google'){
            this.addMarkersGoogle()
          }else{
            this.addMarkers()
          }
        } else if (this.setting.searchMode === 2) {
          this.clearAllMakers()
        }
      },
      addMarkers() {
        this.ifNotExistsWindow()
        this.park.forEach(k => {
          let marker = new this.AMap.Marker({
            map: this.map,
            position: [k.lon, k.lat],
            icon: require('../../assets/icon/stay.png'),
            extData: k.index,
            offset: new this.AMap.Pixel(-15, -40)
          })
          marker.on('click', () => {
            this.infoWindow.setContent(this.getPanel(k, null, null, () => {
              this.showRealScene(k.lon, k.lat, k.Direct, k.ObjectID)
            }))
            this.infoWindow.open(this.map, marker.getPosition())
            this.isShowRealScene && this.showRealScene(k.lon, k.lat, k.Direct, k.ObjectID)
          })
          this.overLays.push(marker)
        })
        this.markers = this.overLays.slice()
        // 添加聚合
        const opts = {
          url: require('../../assets/icon/darkRed.png'),
          size: new this.AMap.Size(48, 48),
          offset: new this.AMap.Pixel(-24, -24),
          textColor: '#FFF'
        }
        const sts = [opts, opts, opts, opts, opts]
        this.map.plugin(["AMap.MarkerClusterer"], () => {
          let cluster = new this.AMap.MarkerClusterer(this.map, this.overLays, {
            styles: sts,
            gridSize: 80,
            maxZoom: 17
          })
          this.overLays.push(cluster)
          this.cluster = cluster
        })
        this.map.setFitView()
      },
      addMarkersBD(){
        this.ifNotExistsWindow()
        this.cluster && this.cluster.clearMarkers(this.overLays)
        this.park.forEach(k => {
          let marker  = new this.BMap.Marker(
          new this.BMap.Point(k.lon, k.lat), {
            icon: new this.BMap.Icon(
              require('../../assets/icon/stay.png'),
              new this.BMap.Size(32, 42), {
                anchor: new this.BMap.Size(32, 42)
              })
          })
          marker.extData = k.index
          marker.on('click', () => {
            this.infoWindow.setContent(this.getPanel(k, null, null, () => {
              this.showRealScene(k.lon, k.lat)
            }))
            this.infoWindow.open(this.map,[k.lon, k.lat])
            this.infoWindow.setId(marker.getExtData())
          })
          marker.setMap(this.map)
          this.overLays.push(marker)
        })
          let cluster = new window.BMapLib.MarkerClusterer(this.map, {
            markers: this.overLays
          })
          cluster.getMap = () => this.map
          this.cluster = cluster
          // cluster.setMap = function (m) { m ? m.addOverlay(this) : (this.getMap() && this.getMap().removeOverlay(this)) }
       
        this.setFitViews(this.map, this.overLays)

        this.markers = this.overLays.slice()
      },
      addMarkersGoogle(){
        this.ifNotExistsWindow()
        this.cluster && this.cluster.clearMarkers(this.overLays)
        let locations = this.park.map(k=>{
          let item = {
           lat: k.lat,
           lng:k.lon
          }
          return item
        })
        let markers = locations.map((location, i) => {
          return new this.google.maps.Marker({
            position: location,
            icon: {
              url: require('../../assets/icon/stay.png'),
              anchor: new this.google.maps.Point(15, 40),
              size:new google.maps.Size(30, 40),
              // scaledSize: new google.maps.Size(38, 38), 
            },
          });
        });
        markers.map((item, index) => {
            item.addListener("click", () => {
              this.infoWindow.setContent(this.getPanelGoogle(this.park[index], null, null))
              this.infoWindow.open(this.map, new this.google.maps.LatLng(locations[index].lat, locations[index].lng))
            });
        });
        const styleCluster = [
          MarkerClusterer.withDefaultStyle({
            width: 32,
            height: 32,
            anchorIcon: [32, 32],
            textColor: "white",
            textSize: 10,
          }),
          MarkerClusterer.withDefaultStyle({
            width: 50,
            height: 64,
            anchorIcon: [32, 25],
            textColor: "yellow",
            textSize: 10,
          }),
          MarkerClusterer.withDefaultStyle({
            width: 100,
            height: 128,
            anchorIcon: [64, 50],
            textColor: "green",
            textSize: 10,
          }),
        ];
        let cluster = new MarkerClusterer(this.map, markers,{
          styles:styleCluster,
          calculator:(clusterMarkers)=>{
            const count = clusterMarkers.length;
            const iconUrl = require('../../assets/icon/darkRed.png')
            return {
              text: count.toString(),
              index:0,
              url: iconUrl,
            }
          }
        });
        this.cluster = cluster
      },
      async addLine(row) {
        // 查询轨迹数据
        const data = await this.fetchTrackData(row.startTime, row.endTime)
        if (data.length === 0) {
          this.$message('没有查询到数据')
          return
        }

        if (window.BMap){
          let polyline = new this.BMap.Polyline(data.map(k => new this.BMap.Point(k.Lon, k.Lat)),
          {
            strokeOpacity: 1,
            strokeColor: '#2196F3',
            strokeWeight: 7,
          })
          polyline.setMap(this.map)
          this.overLays.push(polyline)
        }else if(window.google){
          let polyline = new this.google.maps.Polyline({
            map: this.map,
            //borderWeight: 0,
            strokeColor:'#2196F3',
            strokeWeight: 7,
            //lineJoin: 'round',
            //showDir: this.setting.isBlodLine,
            path: data.map(k => new this.google.maps.LatLng(k.Lat, k.Lon))
          })

          this.overLays.push(polyline)
        }else{
            let polyline = new this.AMap.Polyline({
              map: this.map,
              borderWeight: 0,
              strokeColor: '#2196F3',
              strokeWeight: 7,
              lineJoin: 'round',
              showDir: true,
              path: data.map(k => ([k.Lon, k.Lat]))
            })
           this.overLays.push(polyline)
          this.initSimpleMarker()
        }
        this.ifNotExistsWindow()

        if (window.BMap){
          var startMarker = new this.BMap.Marker(
          new this.BMap.Point(row.beginLon, row.beginLat), {
            icon: new this.BMap.Icon(
              require('../../assets/icon/start.svg'),
              new this.BMap.Size(32, 32), {
                anchor: new this.BMap.Size(32, 32)
              })
          })
          var endMarker = new this.BMap.Marker(
          new this.BMap.Point(row.endLon, row.endLat), {
            icon: new this.BMap.Icon(
              require('../../assets/icon/end.svg'),
              new this.BMap.Size(32, 32), {
                anchor: new this.BMap.Size(32, 32)
              })
          })
          startMarker.setMap(this.map)
          endMarker.setMap(this.map)
        }else if(window.google){
          var startMarker = new this.google.maps.Marker({
            position: { lng: row.beginLon, lat: row.beginLat },
            map: this.map,
            icon: {
              url: require('../../assets/icon/start.svg'),
              anchor: new this.google.maps.Point(16, 32)
            },
          })
          var endMarker = new this.google.maps.Marker({
            position: { lng: row.endLon, lat: row.endLat },
            map: this.map,
            icon: {
              url: require('../../assets/icon/end.svg'),
              anchor: new this.google.maps.Point(16, 32)
            },
          })
        }else{
          var startMarker = new this.SimpleMaker({
            iconLabel: '',
            iconStyle: {
              src: require('../../assets/icon/start.svg'),
              style: {
                width: '24px'
              }
            },
            map: this.map,
            position: [row.beginLon, row.beginLat]
          })
          var endMarker = new this.SimpleMaker({
            iconLabel: '',
            iconStyle: {
              src: require('../../assets/icon/end.svg'),
              style: {
                width: '24px'
              }
            },
            map: this.map,
            position: [row.endLon, row.endLat]
          })
        }

        if(this.google){
          startMarker.addListener('click', () => {
             let newRow = Object.assign({}, row, {
              lon: row.beginLon,
              lat: row.beginLat,
              slon: row.beginSLon,
              slat: row.beginSLat
            })
            this.infoWindow.setContent(this.getPanelGoogle(newRow, [{
                title: this.$t('placeManage.startTime'),
                text: miment(row.startTime).format()
              },
              {
                title: this.$t('placeManage.roadAll'),
                text: row.desc
              },
              {
                title: this.$t('placeManage.length'),
                text: row.mile + 'km'
              },
            ], this.$t('placeManage.startPointer')
            ))
            this.infoWindow.open(this.map, startMarker.getPosition())
          })
          endMarker.addListener('click', () => {
            let newRow = Object.assign({}, row, {
              lon: row.endLon,
              lat: row.endLat,
              slon: row.endSLon,
              slat: row.endSLat
            })
            this.infoWindow.setContent(this.getPanelGoogle(newRow, [{
                title: this.$t('placeManage.endTime'),
                text: miment(row.endTime).format()
              },
              {
                title: this.$t('placeManage.roadAll'),
                text: row.desc
              },
              {
                title: this.$t('placeManage.length'),
                text: row.mile + 'km'
              },
            ], this.$t('placeManage.endPointer') ))
            this.infoWindow.open(this.map, endMarker.getPosition())
          })

        }else{
          startMarker.on('click', () => {
            const newRow = Object.assign({}, row, {
              lon: row.beginLon,
              lat: row.beginLat,
              slon: row.beginSLon,
              slat: row.beginSLat
            })
            this.infoWindow.setContent(this.getPanel(newRow, [{
                title: this.$t('placeManage.startTime'),
                text: miment(row.startTime).format()
              },
              {
                title: this.$t('placeManage.roadAll'),
                text: row.desc
              },
              {
                title: this.$t('placeManage.length'),
                text: row.mile + 'km'
              },
            ], this.$t('placeManage.startPointer'), () => {
              this.showRealScene(row.beginLon, row.beginLat)
            }))
            this.infoWindow.open(this.map, startMarker.getPosition())
            this.isShowRealScene && this.showRealScene(row.beginLon, row.beginLat)
          })
          endMarker.on('click', () => {
            const newRow = Object.assign({}, row, {
              lon: row.endLon,
              lat: row.endLat,
              slon: row.endSLon,
              slat: row.endSLat
            })
            this.infoWindow.setContent(this.getPanel(newRow, [{
                title: this.$t('placeManage.endTime'),
                text: miment(row.endTime).format()
              },
              {
                title: this.$t('placeManage.roadAll'),
                text: row.desc
              },
              {
                title: this.$t('placeManage.length'),
                text: row.mile + 'km'
              },
            ], this.$t('placeManage.endPointer'), () => {
              this.showRealScene(row.endLon, row.endLat)
            }))
            this.infoWindow.open(this.map, endMarker.getPosition())
            this.isShowRealScene && this.showRealScene(row.endLon, row.endLat)
          })
        }
        
        this.overLays.push(startMarker)
        this.overLays.push(endMarker)
        if (window.BMap && (this.map instanceof window.BMap.Map)){
          this.setFitViews(this.map,this.overLays)
        }else if(this.google){
          let fitLine = data.map(k => {
            let item = {
              lng:k.Lon,
              lat:k.Lat
            }
            return item
          })
          this.setFitViewGoogle(this.map,fitLine)
        }else{
          this.map.setFitView()
        }
      },
      clearAllMakers() {
        this.cluster && this.cluster.clearMarkers(this.overLays)
        this.overLays.forEach(k => {
          k.setMap(null)
          k = null
        })
        this.overLays.splice(0)
        this.infoWindow && this.infoWindow.close()
        this.clearHomeAndCompany()
      },
      getPanel(item, arr = null, title = null, cb) {
        arr = arr || [{
            title: this.$t('placeManage.startTime'),
            text: miment(item.startTime).format()
          },
          {
            title: this.$t('placeManage.endTime'),
            text: miment(item.endTime).format()
          },
          {
            title: this.$t('placeManage.stayTimes'),
            text: item.duration
          }
        ]
        title = title || this.group.vehicle.VehicleNum
        let panel = this.$dataPanel.create({
          bgColor: true,
          title,
          lon: item.slon,
          lat: item.slat,
          datas: arr,
          links: ['查看实景'],
          onLinkClick: () => {
            cb && cb()
          },
          paddingLeft: '6em',
          showMore: false,
          titleBackground: '#1E88E5',
          close: true,
          //visible: this.panel_open,
          onClose: () => {
            this.map.clearInfoWindow()
          },
          onChange: () => {}
        })
        return panel
      },
      getPanelGoogle(item, arr = null, title = null, cb) {
        arr = arr || [{
            title: this.$t('placeManage.startTime'),
            text: miment(item.startTime).format()
          },
          {
            title: this.$t('placeManage.endTime'),
            text: miment(item.endTime).format()
          },
          {
            title: this.$t('placeManage.stayTimes'),
            text: item.duration
          }
        ]
        title = title || this.group.vehicle.VehicleNum
        let panel = this.$dataPanel.create({
          bgColor: true,
          title,
          lon: item.slon,
          lat: item.slat,
          datas: arr,
          onLinkClick: () => {
            cb && cb()
          },
          paddingLeft: '7.5em',
          showMore: false,
          titleBackground: '#1E88E5',
          close: true,
          //visible: this.panel_open,
          onClose: () => {
            this.infoWindow.close()
          },
          onChange: () => {}
        })
        return panel
      },
      setFitViews(map, markers) {
        const points = []
        markers.forEach(k => {
          if (k.getPosition) {
            points.push(k.getPosition())
          } else if (k.getPath) {
            k.getPath().forEach(j => points.push(j))
          }
        })
        if (points.length) {
          this.map.setViewport(points)
        }
      },
      setFitViewGoogle(map, markers) {
        const bounds = new window.google.maps.LatLngBounds()
        if (markers instanceof Array && markers && markers.length > 0) {
          var k = markers[0]
          if (k instanceof window.google.maps.Marker) {
            const points = markers.map(k => k.getPosition())

            for (let i = 0; i < points.length; i++) {
              bounds.extend(points[i])
            }
          } else if (k instanceof window.google.maps.LatLng) {
            for (let i = 0; i < markers.length; i++) {
              bounds.extend(markers[i])
            }
          } else if (k.lng && k.lat) {
            for (let i = 0; i < markers.length; i++) {
              bounds.extend(markers[i])
            }
          }

            map.fitBounds(bounds)
        } else {
          console.error('error occured in setFitView, invlaid input')
        }
      },
      showHomeAddress() {
        if (!this.group.people || !this.group.people.HomeLon || !this.group.people.HomeLat) {
          this.$message('没有设置家的位置')
          return
        }
        if (this.homeMarker) {
          this.map.setCenter(this.homeMarker.getPosition())
          return
        }
        const lon = this.group.people.HomeLon
        const lat = this.group.people.HomeLat
        if(this.google){
          var marker = new this.google.maps.Marker({
            position: { lng: lon, lat: lat},
            map: this.map,
            icon: {
              url: require('../../assets/icon/home.svg'),
              anchor: new this.google.maps.Point(16, 32),
              size:new google.maps.Size(24, 24),
              scaledSize: new google.maps.Size(24, 24)
            },
          })
          // marker.addListener('click', () => {
          //   let newRow = Object.assign({}, row, {
          //     lon: lon,
          //     lat: lat
          //   })
          //   this.infoWindow.setContent(this.getPanelGoogle(newRow, [{
          //       title: this.$t('placeManage.lonlat'),
          //       text: lon + ',' + lat
          //     },
          //     {
          //       title: this.$t('placeManage.location'),
          //       text: this.group.people.HomeAddress
          //     }
          //   ], this.$t('placeManage.showHome')
          //   ))
          //   this.infoWindow.open(this.map, marker.getPosition())
          // })
          this.homeMarker = marker
          // this.map.setZoom(18)
        }else if(this.BMap){
          let marker = new this.BMap.Marker(
            new this.BMap.Point(lon, lat),
            {
            icon: new this.BMap.Icon(
              require('../../assets/icon/home.svg'),
              new this.BMap.Size(24, 24),
              {
                anchor: new this.BMap.Size(24, 24),
                imageSize: new this.BMap.Size(24, 24)
              })
          })
          marker.setMap(this.map)
          this.homeMarker = marker
          this.map.centerAndZoom(new window.BMap.Point(lon, lat), 16)

        }else{
          let marker = new this.SimpleMaker({
            iconLabel: {
              innerHTML: `<img src="${require('../../assets/icon/home.svg')}" style="height:24px;width:24px;background:#FFF;border-radius:50%;padding:2px;transform:translateY(6px);">`
            },
            iconTheme: 'fresh',
            iconStyle: 'red',
            map: this.map,
            position: [lon, lat]
          })
          marker.on('click', () => {
            this.infoWindow.setContent(this.getPanel(null, [{
                title: this.$t('placeManage.lonlat'),
                text: lon + ',' + lat
              },
              {
                title: this.$t('placeManage.location'),
                text: this.group.people.HomeAddress
              }
            ], this.$t('placeManage.showHome'), () => {
              this.showRealScene(lon, lat)
            }))
            this.infoWindow.open(this.map, marker.getPosition())
            this.isShowRealScene && this.showRealScene(lon, lat)
          })
          this.map.setCenter([lon, lat])
          this.homeMarker = marker
          this.map.setCenter(new this.google.maps.LatLng(lat, lon))
          this.map.setZoom(14)
        } 

      },
      showCompanyAddress() {
        if (!this.group.people || !this.group.people.CompanyLon || !this.group.people.CompanyLat) {
          this.$message('没有设置公司位置')
          return
        }
        if (this.companyMarker) {
          this.map.setCenter(this.companyMarker.getPosition())
          return
        }
        const lon = this.group.people.CompanyLon
        const lat = this.group.people.CompanyLat

        if(this.google){
          var marker = new this.google.maps.Marker({
            position: { lng: lon, lat: lat},
            map: this.map,
            icon: {
              url: require('../../assets/icon/department.svg'),
              anchor: new this.google.maps.Point(16, 32),
              size:new google.maps.Size(24, 24),
              scaledSize: new google.maps.Size(24, 24)
            },
          })
          // marker.addListener('click', () => {
          //   let newRow = Object.assign({}, row, {
          //     lon: lon,
          //     lat: lat
          //   })
          //   this.infoWindow.setContent(this.getPanelGoogle(newRow, [{
          //       title: this.$t('placeManage.lonlat'),
          //       text: lon + ',' + lat
          //     },
          //     {
          //       title: this.$t('placeManage.location'),
          //       text: this.group.people.HomeAddress
          //     }
          //   ], this.$t('placeManage.showHome')
          //   ))
          //   this.infoWindow.open(this.map, marker.getPosition())
          // })
          this.companyMarker  = marker
          this.map.setCenter(new this.google.maps.LatLng(lat, lon))
          this.map.setZoom(14)
        }else if(this.BMap){
          let marker = new this.BMap.Marker(
            new this.BMap.Point(lon, lat),
            {
            icon: new this.BMap.Icon(
              require('../../assets/icon/department.svg'),
              new this.BMap.Size(24, 24),
              {
                anchor: new this.BMap.Size(24, 24),
                imageSize: new this.BMap.Size(24, 24)
              })
          })
          marker.setMap(this.map)
          this.companyMarker = marker
          this.map.centerAndZoom(new window.BMap.Point(lon, lat), 16)
        }else{
          let marker = new this.SimpleMaker({
            iconLabel: {
              innerHTML: `<img src="${require('../../assets/icon/department.svg')}" style="height:24px;width:24px;background:#FFF;border-radius:50%;padding:2px;transform:translateY(6px);">`
            },
            iconTheme: 'fresh',
            iconStyle: 'red',
            map: this.map,
            position: [lon, lat]
          })
          marker.on('click', () => {
            this.infoWindow.setContent(this.getPanel(null, [{
                title: this.$t('placeManage.lonlat'),
                text: lon + ',' + lat
              },
              {
                title: this.$t('placeManage.location'),
                text: this.group.people.CompanyAddress
              }
            ], this.$t('placeManage.showCompany'), () => {
              this.showRealScene(lon, lat)
            }))
            this.infoWindow.open(this.map, marker.getPosition())
            this.isShowRealScene && this.showRealScene(lon, lat)
          })
          this.map.setCenter([lon, lat])
          this.companyMarker = marker
        }
      },
      clearHomeAndCompany() {
        this.homeMarker && this.homeMarker.setMap(null)
        this.companyMarker && this.companyMarker.setMap(null)
        this.homeMarker = null
        this.companyMarker = null
      },
      exportTrip() {
        this.exportExcelLoading = true
        ExportTrip(this.itemId, this.setting.startTime, this.setting.endTime).then(ret => {
          const name = '线路详情_' + this.group.vehicle.VehicleNum + '.xlsx'
          saveBlob(ret.data, name)
        }).finally(() => {
          this.exportExcelLoading = false
        })
      },
      exportPark() {
        this.exportExcelLoading = true
        ExportPark(this.itemId, this.setting.stopMinutes, this.setting.startTime, this.setting.endTime).then(ret => {
          const name = '停留详情_' + this.group.vehicle.VehicleNum + '.xlsx'
          saveBlob(ret.data, name)
        }).finally(() => {
          this.exportExcelLoading = false
        })
      },
      rowClick(row) {
        if (this.setting.searchMode === 1) {
          if (window.BMap && (this.map instanceof window.BMap.Map)){
            this.map.setZoom(18)
            this.map.setCenter(new this.BMap.Point(row.lon, row.lat))
            this.infoWindow.setContent(this.getPanel(row, null, null, () => {
              this.showRealScene(row.lon, row.lat)
            }))
            this.infoWindow.open(this.map, [row.lon, row.lat])
          }else if(this.google){
            // if (this.map.getZoom() <14) {
            //   this.map.setZoom(18)
            // }
            this.map.setZoom(20)
            this.map.setCenter(new this.google.maps.LatLng(row.lat, row.lon))
            this.infoWindow.setContent(this.getPanelGoogle(row, null, null))
            this.infoWindow.open(this.map, new this.google.maps.LatLng(row.lat, row.lon))
          }else{
            this.map.setZoom(18)
            this.map.setCenter([row.lon, row.lat])
            this.infoWindow.setContent(this.getPanel(row, null, null, () => {
              this.showRealScene(row.lon, row.lat)
            }))
            this.infoWindow.open(this.map, [row.lon, row.lat])
          }
        } else {
          this.clearAllMakers()
          this.overLays = []
          this.addLine(row)
        }
      },
      fitView() {
        if (this.setting.searchMode === 1) {
          this.map.setFitView(this.markers)
        } else {
          this.map.setFitView()
        }
      },
      clickDevice(item) {
        this.isOnSearch = false
        this.objectId = item.ObjectID
      },
      setRange(startTime, endTime) {
        this.setting.startTime = startTime
        this.setting.endTime = endTime
        this.itemId && this.search()
      },
      setRangeToday() {
        this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss'), miment())
      },
      setRangeYesterday() {
        this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss').add(-1, 'DD'), miment().add(-1, 'DD').set(23,
          'hh').set(59, 'mm').set(59, 'ss'))
      },
      setRangeBeforeYesterday() {
        this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss').add(-2, 'DD'), miment().add(-2, 'DD').set(23,
          'hh').set(59, 'mm').set(59, 'ss'))
      },
      setRangeThreeDays() {
        this.setRange(miment().set(0, 'hh').set(0, 'mm').set(0, 'ss').add(-2, 'DD'), miment())
      }
    },
    created() {
      this.miment = miment
      this.overLays = []
     
      this.$once('hook:beforeDestroy', () => {
        window.qq = null
        window.soso = null
      })
    },
    mounted() {
        this.initMap(this.currentMap).then(res=>{
        this.objectId = this.itemId
        this.fetchData()
        this.setRangeThreeDays()
      })
    },
    mixins: [
      mixinMapCachePosition
    ]
  }

</script>

<style lang="scss" scoped>
  @import '../../styles/mixin.scss';
  @import '../../styles/variables-simple.scss';
  @include v-wrapper;
  @include v-area;
  @include v-item;

  .v-wrapper {
    .tingliu {
      line-height: normal;

      & ::v-deep .el-input-group__prepend {
        padding: 0 12px;
        line-height: normal;
      }
    }

    $nav-width: 320px;

    .v-pano.is-show {
      // background-color: black;
      margin-top: $page-main-tool-height;
      width: calc(50% - 200px);
      position: relative;

      .closeBtn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 5;
        font-size: 16px;
        padding: 8px 9px;
        border-radius: 2px;
        opacity: .7;
        background-color: $--color-danger;
        color: #FFF;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }

    .v-pano {
      .v-wrap {
        height: 105%;

        .box {
          width: 100%;
          height: 100%;
        }
      }

      .nofollow {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }

    .v-nav--right {
      @include scroll-bar;
      width: $nav-width;
      flex-shrink: 0;
      position: absolute;
      right:0;
      background-color: #fff;
      border-left: 1px solid #DCDFE6;
      z-index: 112;
      overflow: visible; //overflow-y: auto;

      .sub-title {
        // position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 113;
        border-bottom: 1px solid #DCDFE6;
        background-color: $home-backcolor;
        color: $home-colorprimary;
        font-size: 13px;
        height: 40px;
        line-height: 40px;

        .action {
          float: right;
          font-size: 13px;
          cursor: pointer;
        }
      }

      .trip,
      .park {
        // padding-top: 36px;
      }
    }
  }

  .searchIcon {
    & i {
      float: right;
      height: 24px;
      line-height: 24px;
    }

    &:hover {
      color: #F4511E;
      cursor: pointer;
    }
  }

  .v-wrapper .toggleTable {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    z-index: 102;
    // font-size: 56px;
    cursor: default;

    &.expand {
      right: 320px;
    }
  }

  .en,
  .fr {
    width: 340px !important;

    .v-item {
      .v_title {
        width: 9em;
      }

      .v_text {
        margin-left: 9em;
      }
    }
  }

  .v-places {
    width: 340px !important;
  }

  .v-place {

    h2 {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-top: 6px;
      font-size: 16px;
      font-weight: normal;
      color: #666666;
      border-top: 1px solid #dcdcdc;
      display: none;
    }

    .dateRadios {
      // padding-top: 5px;
      // padding-bottom: 10px;
      text-align: right;
      padding-right: 20px;

      span {
        display: inline-block;
        // width:20%;
        text-align: center;
        font-size: 12px;
        color: #1890ff;
        padding: 6px 0px;
        // background-color: #f0f2f7;
        border-radius: 20px;
        // margin-bottom: 10px;
        margin-right: 5px;
        cursor: pointer;
        text-decoration: none;
      }

      span:last-child {
        margin-right: 0;
      }

      span:hover {
        color: #1890ff;
      }

      .active {
        color: #1890ff;
      }
    }

    .v-lines {
      position: relative;
      margin: 0px auto 10px auto;
      padding: 0 15px;
      min-height: 30px;
      overflow: hidden;
      display: flex;

      .v_title {
        white-space: nowrap;
        height: 30px;
        line-height: 30px;
        max-width: 90px;
        font-size: 14px;
        color: #303133;
      }

      .v_text {
        .el-date-editor.el-input {
          width: 100%;
          border-radius: 20px;

          & ::v-deep .el-input__inner {
            border-radius: 20px;
          }
        }

        .el-radio {
          margin-bottom: 5px;
        }
      }

      .v-device {
        padding-top: 5px;
      }

      .tingliu {
        .el-input {
          & ::v-deep .el-input-group__prepend {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }

          & ::v-deep .el-input__inner {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          & ::v-deep .el-input__suffix {
            top: 8px;
            right: 12px;
          }
        }
      }
    }

    .v-btns {
      text-align: center;

      .el-button {
        width: 90%;
        border-radius: 20px;
      }
    }
  }


  .block {
    margin: 0 8px 10px 8px;
    cursor: pointer;

    h1 {
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }

    .box {
      background-color: #f1f2f7;
      padding: 10px;
      border-radius: 10px;

      .caption {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        font-size: 12px;

        .times {
          color: #8994a7;

          span {
            display: inline-block;
            color: #8994a7;
            width: 20px;
            margin-right: 5px;

            .icon {
              font-size: 16px;
            }
          }

        }

        .mile {
          color: #8994a7;
        }

        em {
          color: #8994a7;
          line-height: 20px;
          font-style: normal;
        }
      }

      .row {
        margin-top: 10px;
        display: flex;

        span {
          width: 20px;
          margin-right: 5px;
          font-size: 18px;
          position: relative;
          z-index: 1;
          color: #4d6182;

          .icon {
            position: absolute;
            // left: -2px;
            z-index: 1;
          }
        }

        p {
          flex-grow: 1;
          width: 0;
          font-size: 12px;
          line-height: 18px;

          span {
            color: #4d6182;
            font-size: 12px;
          }
        }
      }

    }

  }

</style>
