import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function GetPark(ObjectID, limitMinutes, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/GetPark',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      limitMinutes,
      startTime,
      endTime
    })
  })
}

export function GetTrip(ObjectID, startTime, endTime) {
  const loginKey = getToken()
  return request({
    url: './query/GetTrip',
    method: 'post',
    timeout: 60000,
    data: Object.assign({}, commonParams, {
      loginKey,
      ObjectID,
      startTime,
      endTime
    })
  })
}
